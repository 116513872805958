import React, { Component } from 'react';
import { Route } from 'react-router';
import { HashRouter } from 'react-router-dom';

import Home from '../home/Home';
import PostPage from '../postPage/PostPage';
import Post from '../post/Post';
import About from '../about/About';

import './Body.css';

class Body extends Component {
  constructor (props) {
    super(props);
    this.state = {
      postName: props.name
    };
  }

  render () {
    return (
      <div className="blog_body">
        <div>
          <HashRouter>
            <div>
              <Route exact path='/' component={Home}/>
              <Route path='/posts/:name' component={Post} />
              <Route exact path='/posts' component={PostPage}/>
              <Route exact path='/about' component={About}/>
            </div>
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default Body;
