import React, { Component } from 'react';

import './About.css';
import Navigation from '../navigation/Navigation';
import Title from '../title/Title';
import Paragraph from '../paragraph/Paragraph';

import {
  isMobile
} from 'react-device-detect';

const content = [
  'Welcome to Code for the Cloud!',
  'In a multi cloud world how do you keep up with the numerous services updated and released each year? How do you decide which services best suit your application? What can you do to guarantee reliability of your production workloads while allowing for applications to be consistently updated?',
  "My name is Tom and I am a VMware consultant within the Professional Services specialising in automation.",
  "When working on my day to day work and also developing side projects, I found that it could be difficult figuring out where to start with new technologies especially in the multi-cloud space. There are a large variety of tools and it can be hard to decide what is the right tool for you and can be harder to learn how to build systems to work as you intended.",
  "I built this website to help explain some of these technologies as well as show how these can be used in practise."
];

class About extends Component {
  generateParagraphs () {
    var result = [];
    for (var i in content) {
      result.push(<Paragraph key={i} text={content[i]} />);
    }
    return result;
  }

  render () {
    var paragraphs = this.generateParagraphs();
    if (isMobile) {
      return (
        <div>
          <Title title={'About Me'}/>
          <div>
            <Navigation/>
          </div>
          <div className = "home-mob">
            {paragraphs}
          </div>

        </div>
      );
    } else {
      return (
        <div>
          <Title title={'About Me'}/>
          <div>
            <Navigation/>
          </div>
          <div className = "home-web">
            {paragraphs}
          </div>

        </div>
      );
    }
  }
}

export default About;
