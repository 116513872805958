import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// Import classes for postgen
import Heading from '../heading/Heading';
import CodeSnippet from '../codeSnippet/CodeSnippet';
import Paragraph from '../paragraph/Paragraph';
import Image from '../image/Image';
import List from '../list/List';
import Title from '../title/Title';
import Navigation from '../navigation/Navigation';

import {
  isMobile
} from 'react-device-detect';

// Import css
import './Post.css';

const url = 'https://64mr0kzglg.execute-api.ap-southeast-2.amazonaws.com/prod';

class Post extends Component {
  static get propTypes() {
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      })
    };
  }
  
  constructor (props) {
    super(props);
    this.state = {
      postName: '',
      post: ''
    };
  }

  componentDidMount () {
    if (this.props.match) {
      const { name } = this.props.match.params;
      axios.defaults.baseURL = url;
      axios.defaults.headers.get['Content-Type'] = 'application/json';
      axios.get('/posts/' + name)
        .then(res => {
          const data = res.data;
          this.setState({ post: data, postName: name });
        });
    }
  }

  addPostObject (result, i, value) {
    if ('heading1' in value) {
      result.push(<Heading key={i} text={value.heading1} level={'1'}/>);
    } else if ('heading2' in value) {
      result.push(<Heading key={i} text={value.heading2} level={'2'}/>);
    } else if ('heading3' in value) {
      result.push(<Heading key={i} text={value.heading3} level={'3'}/>);
    } else if ('paragraph' in value) {
      result.push(<Paragraph key={i} text={value.paragraph} />);
    } else if ('code' in value) {
      result.push(<CodeSnippet key={i} text={value.code[0]} code={value.code[1]}/>);
    } else if ('image' in value) {
      result.push(<Image key={i} title={this.state.postName} image={value.image[0]} alt={value.image[1]}/>);
    } else if ('list' in value) {
      result.push(<List key={i} list={value.list} />);
    }
  }

  createPost () {
    var post = this.state.post;
    var result = [];
    if (post) {
      result.push(<Title key={'title'} title={post.title}/>);
      result.push(<Heading key={'heading'} text={post.title} level={'1'}/>);
      result.push(<p key={'author'} className="subHeading">{'By: ' + post.author}<br></br>{'Published: ' + post.date} </p>);
      if (post != null) {
        if ('content' in post) {
          for (var i in post.content) {
            var value = post.content[i];
            this.addPostObject(result, i, value);
          }
        }
      }
    }
    return result;
  }

  render () {
    var post = this.createPost();
    if (isMobile) {
      return (
        <div>
          <Navigation/>
          <div className="post-mob">
            {post}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Navigation/>
          <div className="post-web">
            {post}
          </div>
        </div>
      );
    }
  }
}

export default Post;
