import React from 'react';
import PropTypes from 'prop-types';


class Title extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string.isRequired
    };
  }
  
  constructor (props) {
    super(props);
    this.state = {
      title: props.title
    };
  }

  componentDidMount () {
    document.title = this.state.title;
  }

  render () {
    return (
      null
    );
  }
}

export default Title;
