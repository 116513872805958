import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Heading.css';

class Heading extends Component {
  static get propTypes() {
    return {
      text: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired

    };
  }

  constructor (props) {
    super(props);
    this.state = {
      text: props.text,
      level: props.level
    };
  }

  createHeading () {
    var result = null;
    var value = this.state.text;
    var type = this.state.level;
    if (type === '1') {
      result = <h1 key='0'>{value}</h1>;
    } else if (type === '2') {
      result = <h2 key='0'>{value}</h2>;
    } else if (type === '3') {
      result = <h3 key='0'>{value}</h3>;
    }

    return result;
  }

  render () {
    var text = this.createHeading();
    return (
      <div className="heading">
        {text}
      </div>
    );
  }
}

export default Heading;
