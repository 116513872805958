import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './Card.css';

class BlogCard extends Component {
  static get propTypes() {
    return {
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired
    };
  }
  
  constructor (props) {
    super(props);
    this.state = {
      title: props.title,
      body: props.body,
      link: props.link,
      date: props.date
    };
  }

  render () {
    var title = this.state.title;
    var link = this.state.link;
    var body = this.state.body;
    var date = this.state.date;
    return (
      <div>
        <hr/>
        <h2>{title}</h2>
        <p>{date}</p>
        <p>{body}</p>
        <Button className="mt-auto" color="primary" href={link}>View Post</Button>
      </div>
     
    );
  }
}

export default BlogCard;
