import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
/// style={docco}
import './CodeSnippet.css';

class CodeSnippet extends Component {
  static get propTypes() {
    return {
      text: PropTypes.any.isRequired,
      code: PropTypes.string.isRequired
    };
  }
  
  constructor (props) {
    super(props);
    this.state = {
      text: props.text,
      code: props.code
    };
  }

  createSnippet () {
    var text = this.state.text;
    if(this.state.code === "JSON"){
      console.log("Code is json");
      text = JSON.stringify(this.state.text, null, 2);

    }
    var code = this.state.code;
    console.log("Code type is :" + code);
    var result = [];
    if (text && code) {
      result.push(<SyntaxHighlighter key='0' language={code} >{text}</SyntaxHighlighter>);
    }
    return result;
  }

  render () {
    var text = this.createSnippet();

    return (
      <div>{text}</div>
    );
  }
}

export default CodeSnippet;
