import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Image.css';

class Image extends Component {
  static get propTypes() {
    return {
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    };
  }
  
  constructor (props) {
    super(props);
    this.state = {
      title: props.title,
      image: props.image,
      alt: props.alt
    };
  }

  render () {
    var name = this.state.title;
    var image = this.state.image;
    var alt = this.state.alt;

    return (
      <div>
        <a href={'/posts/' + name + '/' + image}>
          <img src={'/posts/' + name + '/' + image} alt={alt} className="img-fluid"/>
        </a>
        <p></p>
      </div>
    );
  }
}

export default Image;
