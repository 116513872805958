import React, { Component } from 'react';
import axios from 'axios';

import Navigation from '../navigation/Navigation';
import BlogCard from '../card/Card';
import Title from '../title/Title';

import {
  isMobile
} from 'react-device-detect';

// Import css
import './PostPage.css';

const url = 'https://64mr0kzglg.execute-api.ap-southeast-2.amazonaws.com/prod';

class PostPage extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  componentDidMount () {
    axios.defaults.baseURL = url;
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    axios.get('/posts')
      .then(res => {
        const data = res.data;
        this.setState({ data: data });
      });
  }

  generateCards () {
    var result = [];
    var commonLink = '/#/posts/';
    var posts = this.state.data;
    
    if (posts) {
      posts = posts.body.posts;
      for (var i = 0; i < posts.length; i++) {
        result.push(<BlogCard key={i} title={posts[i].title} date={posts[i].date} body = {posts[i].summary} link = {commonLink + posts[i].name}/>);
      }
    }
    return result;
  }

  render () {
    var posts = this.generateCards();
    if (isMobile) {
      return (
        <div>
          <Title title={'Code for the Cloud'}/>
          <Navigation/>
          <div className="post-page">
            <div className="mob-post">
              {posts}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Title title={'Code for the Cloud'}/>
          <Navigation/>
          <div className="post-page">
            <div className="web-post">
              {posts}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PostPage;
