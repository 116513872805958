import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './List.css';

class List extends Component {
  static get propTypes() {
    return {
      list: PropTypes.string.isRequired
    };
  }

  constructor (props) {
    super(props);
    this.state = {
      list: props.list
    };
  }


  createInnerList (list) {
    var result = [];
    for (var i in list) {
      result.push(<li className="list" key={i}>{list[i]}</li>);
    }

    return result;
  }

  createList () {
    var result = [];

    if (this.state.list) {
      // Replace newlines with the inner list headers
      var list = this.state.list;
      var l = list.split('\n');
      // Surround the list with outer list defintion
      // Need to add in 2 inner ones that wont be covered by the replace function
      result.push(<ul className="postList" key='0'>{this.createInnerList(l)}</ul>);
    }
    return result;
  }

  render () {
    var list = this.createList();
    return (
      <div>
        {list}
      </div>
    );
  }
}

export default List;
